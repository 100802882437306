import { useEffect, useState } from 'react';
import { timeTo24HourFormat } from '../helpers/time';

export default function Clock() {
  const [currentTime, setCurrentTime] = useState(timeTo24HourFormat());

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setCurrentTime(timeTo24HourFormat());
    }, 1000);
    return () => {
      window.clearInterval(intervalId);
    };
  });

  return <>{currentTime}</>;
}
