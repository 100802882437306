export function prepareExitAnimation(
  currentPageElementRefs: React.MutableRefObject<
    Map<string, React.RefObject<HTMLElement>>
  >
): void {
  for (const currentPageElementRef of currentPageElementRefs.current.values()) {
    if (!currentPageElementRef.current) {
      continue;
    }
    const containerClientRect =
      currentPageElementRef.current.getBoundingClientRect();
    currentPageElementRef.current.style.setProperty(
      '--exit-left',
      `${containerClientRect.left}px`
    );
    currentPageElementRef.current.style.setProperty(
      '--exit-top',
      `${containerClientRect.top}px`
    );

    currentPageElementRef.current.style.setProperty(
      '--exit-width',
      `${containerClientRect.width}px`
    );
    currentPageElementRef.current.style.setProperty(
      '--exit-height',
      `${containerClientRect.height}px`
    );
  }
}
