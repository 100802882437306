export const MAX_NUMBER_OF_BOARDS = 10; // Should be in sync with MaxNumberOfBoards on server
export const SECONDS_OF_ERROR_BEFORE_SHOWING_ERROR_MESSAGE = 60;
export const SECONDS_OF_MAXIMUM_EXPECTED_LATENCY = 10;
export const SECONDS_BETWEEN_ALIVE_MESSAGES = 60; // Needs to be synced with SecondsBetweenAliveMessages for server
export const SECONDS_BETWEEN_EVENT_SOURCE_RECONNECTION_ATTEMPTS = 60;
export const SECONDS_OF_EXPECTED_SERVER_STARTUP_TIME = 10;
export const SECONDS_BETWEEN_DEPARTURES_PAGE_CHANGE = 10;
export const HORIZONTAL_BOARD_MINIMUM_ASPECT_RATIO = 16 / 10;

export const MINIMUM_TIME_IN_SECONDS_TO_READ_TEXT = 5;
export const CHARACTERS_READ_PER_SECOND = 20;
export const SCROLL_TIME_IN_SECONDS = 1;
export const TRAFFIC_SITUATION_MINIMUM_NUMBER_OF_CHARACTERS = 50;
export const PAGINATION_TIME_IN_SECONDS = 0.5; // Needs to be in sync with --pagination-duration
