import { useEffect, useState } from 'react';
import { BoardConfiguration } from '../models/boardConfiguration';
import { createBoardConfigurations } from '../helpers/boardConfigurationFactory';
import { logError } from '../helpers/errorLogging';

export default function useBoardConfigurations() {
  const [error, setError] = useState<string | undefined>();
  const [boardConfigurations, setBoards] = useState<
    BoardConfiguration[] | undefined
  >();

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const boards = createBoardConfigurations(urlParams);
      setBoards(boards);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
      logError(error);
    }
  }, []);

  return { boardConfigurations, error };
}
