import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  ConfigurationId,
  IncompleteStopBoardConfiguration,
  StopBoardConfiguration,
} from '../models/boardConfiguration';
import { StopArea } from '../models/stopArea';
import {
  getStopAreaByStopAreaGid,
  getStopAreaByStopPointGid,
} from '../helpers/stopAreas';
import { Module } from '../models/module';
import { DepartureSorting } from '../models/departureSorting';
import StopAreaSearch from './StopAreaSearch';
import StopPointConfigurator, { StopPointGids } from './StopPointsConfigurator';
import styles from './StopBoardConfigurator.module.scss';
import { getDisplayName } from '../helpers/stopArea';

export interface StopBoardConfiguratorProps {
  configuratorId: number;
  configuration?: IncompleteStopBoardConfiguration | StopBoardConfiguration;
  onChanged: (
    configuratorId: number,
    boardConfiguration: StopBoardConfiguration
  ) => void;
}

export default function StopBoardConfigurator({
  configuratorId,
  configuration,
  onChanged,
}: StopBoardConfiguratorProps) {
  const departureSortingName = `departure-sorting-${configuratorId}`;
  const [stopArea, setStopArea] = useState<StopArea | undefined>(undefined);

  useEffect(() => {
    const abortController = new AbortController();

    async function updateStopArea() {
      try {
        const stopArea = await getStopArea();
        setStopArea(stopArea);
      } catch (error) {
        if (error instanceof DOMException && error.name === 'AbortError') {
          // Ignore automatically aborted requests
        } else {
          throw error;
        }
      }
    }

    async function getStopArea(): Promise<StopArea | undefined> {
      if (configuration === undefined || configuration.type === 'stop') {
        return undefined;
      }

      if (configuration.type === 'stop-area') {
        return await getStopAreaByStopAreaGid(
          configuration.stopAreaGid,
          abortController.signal
        );
      }

      const stopPointGid = configuration.stopPointGids[0];
      if (!stopPointGid) {
        return undefined;
      }

      return await getStopAreaByStopPointGid(
        stopPointGid,
        abortController.signal
      );
    }

    void updateStopArea();

    return () => {
      abortController.abort();
    };
  }, [configuration]);

  const onStopAreaChange = useCallback((stopArea: StopArea) => {
    setStopArea(stopArea);
    setStopPointGids('all');
  }, []);

  const [stopPointGids, setStopPointGids] = useState<StopPointGids>(
    configuration?.type === 'stop-points' ? configuration.stopPointGids : 'all'
  );

  const [showPlatformsInHeader, setShowPlatformsInHeader] = useState(
    configuration?.type === 'stop-points' &&
      configuration.showPlatformsInHeader === false
      ? false
      : true
  );
  function onShowPlatformsInHeaderChange(event: ChangeEvent<HTMLInputElement>) {
    setShowPlatformsInHeader(event.target.checked);
  }

  const [modules, setModules] = useState<Module[]>(
    configuration !== undefined ? configuration.modules : []
  );
  function onModuleChange(event: ChangeEvent<HTMLInputElement>) {
    const module = event.target.value as Module;

    if (event.target.checked) {
      setModules((previousModules) => [...previousModules, module]);
    } else {
      setModules((previousModules) =>
        previousModules.filter((previousModule) => previousModule !== module)
      );
    }
  }

  const [departureSorting, setDepartureSorting] = useState<DepartureSorting>(
    configuration?.departureSorting !== undefined
      ? configuration.departureSorting
      : 'alphabetical'
  );
  function onDepartureSortingChange(event: ChangeEvent<HTMLInputElement>) {
    setDepartureSorting(event.target.value as DepartureSorting);
  }

  useEffect(() => {
    function getConfigurationId(): ConfigurationId {
      return configuration === undefined ? -1 : configuration.id;
    }

    function getConfiguration(
      stopArea: StopArea | undefined
    ): StopBoardConfiguration | undefined {
      if (!stopArea) {
        return undefined;
      }

      if (stopPointGids === 'all') {
        return {
          id: getConfigurationId(),
          type: 'stop-area',
          modules,
          departureSorting,
          stopAreaGid: stopArea.gid,
        };
      }

      return {
        id: getConfigurationId(),
        type: 'stop-points',
        modules,
        departureSorting,
        stopPointGids,
        showPlatformsInHeader,
      };
    }

    const boardConfiguration = getConfiguration(stopArea);
    if (boardConfiguration) {
      onChanged(configuratorId, boardConfiguration);
    }
  }, [
    configuratorId,
    configuration,
    departureSorting,
    modules,
    onChanged,
    showPlatformsInHeader,
    stopArea,
    stopPointGids,
  ]);
  return (
    <div className={styles['stop-board-configurator']}>
      <StopAreaSearch
        autoFocus={stopArea === undefined}
        onChange={onStopAreaChange}
      ></StopAreaSearch>

      {stopArea && (
        <fieldset>
          <legend>{getDisplayName(stopArea)} lägen</legend>
          <StopPointConfigurator
            stopArea={stopArea}
            stopPointGids={stopPointGids}
            onChange={setStopPointGids}
          ></StopPointConfigurator>
          {stopPointGids !== 'all' && (
            <label className={styles['show-platforms-in-header']}>
              <input
                type="checkbox"
                checked={showPlatformsInHeader}
                onChange={onShowPlatformsInHeaderChange}
              />
              Visa lägen i headern
            </label>
          )}
        </fieldset>
      )}

      <fieldset>
        <legend>Moduler</legend>
        <label className={styles.option}>
          <input
            type="checkbox"
            value="departures"
            checked={modules.includes('departures')}
            onChange={onModuleChange}
          />
          Avgångar
        </label>
        <label className={styles.option}>
          <input
            type="checkbox"
            value="trafficSituations"
            checked={modules.includes('trafficSituations')}
            onChange={onModuleChange}
          />
          Störningar
        </label>
      </fieldset>

      <fieldset>
        <legend>Sortering av avgångar</legend>
        <label className={styles.option}>
          <input
            type="radio"
            checked={departureSorting === 'alphabetical'}
            name={departureSortingName}
            value="alphabetical"
            onChange={onDepartureSortingChange}
          />
          Alfabetiskt
        </label>
        <label className={styles.option}>
          <input
            type="radio"
            checked={departureSorting === 'time'}
            name={departureSortingName}
            value="time"
            onChange={onDepartureSortingChange}
          />
          Avgångstid
        </label>
      </fieldset>
    </div>
  );
}
