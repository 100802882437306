import { useEffect, useState } from 'react';
import { formatDepartureTime } from '../helpers/formatDepartureTime';
import { Departure } from '../models/departure';

export function useDepartureDisplayTime(departure: Departure | undefined) {
  const [displayTime, setDisplayTime] = useState(getMinutesOrTextTo(departure));

  useEffect(() => {
    setDisplayTime(getMinutesOrTextTo(departure));

    const intervalId = setInterval(() => {
      setDisplayTime(getMinutesOrTextTo(departure));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [departure]);

  function getMinutesOrTextTo(
    departure: Departure | undefined
  ): number | string | undefined {
    if (!departure) {
      return undefined;
    }

    if (departure.isCancelled) {
      return 'Inställd';
    }

    return formatDepartureTime(departure.estimatedOtherwisePlannedTime);
  }

  return displayTime;
}
