import classNames from 'classnames';
import type { TrafficSituation as TrafficSituationModel } from '../models/trafficSituation';
import TrafficSituation from './TrafficSituation';
import styles from './TrafficSituationsModule.module.scss';
import usePagination from '../hooks/usePagination';
import {
  createRef,
  CSSProperties,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import useElementSize from '../hooks/useElementSize';
import { LayoutContext } from './Board';
import {
  PAGINATION_TIME_IN_SECONDS,
  TRAFFIC_SITUATION_MINIMUM_NUMBER_OF_CHARACTERS,
} from '../constants';
import { getRemPropertyPixelSize } from '../helpers/rem';
import PageIndicator from './PageIndicator';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { prepareExitAnimation } from '../helpers/exitAnimation';

interface TrafficSituationsModuleProps {
  trafficSituations: TrafficSituationModel[];
}

export default function TrafficSituationsModule({
  trafficSituations,
}: TrafficSituationsModuleProps) {
  const layout =
    useContext(LayoutContext) === 'horizontal' ? 'vertical' : 'horizontal';

  const trafficSituationContainerRefs = useRef<Map<string, HTMLDivElement>>(
    new Map()
  );
  const [viewTimes, setViewTimes] = useState<Map<string, number>>(new Map());
  const maxViewTime = Math.max(...viewTimes.values());

  const trafficSituationsContainerRef = useRef<HTMLTableSectionElement>(null);
  const trafficSituationsAvailableSize = useElementSize(
    trafficSituationsContainerRef
  );

  const currentPageElementRefs = useRef<
    Map<string, RefObject<HTMLTableRowElement>>
  >(new Map());
  function getOrCreateCurrentPageElementRef(paginationId: string) {
    const existingRef = currentPageElementRefs.current.get(paginationId);
    if (existingRef) {
      return existingRef;
    } else {
      const newRef = createRef<HTMLTableRowElement>();
      currentPageElementRefs.current.set(paginationId, newRef);
      return newRef;
    }
  }
  function removeCurrentPageElementRef(paginationId: string) {
    currentPageElementRefs.current.delete(paginationId);
  }

  const pageIndicatorRef = useRef<HTMLDivElement>(null);
  const pageIndicatorSize = useElementSize(pageIndicatorRef);

  function getAvailableSize(): number | undefined {
    if (layout === 'vertical') {
      return trafficSituationsAvailableSize.height !== undefined &&
        pageIndicatorSize.height !== undefined
        ? trafficSituationsAvailableSize.height - pageIndicatorSize.height
        : undefined;
    }

    return trafficSituationsAvailableSize.width;
  }

  const availableSize = getAvailableSize();

  const { itemsForCurrentPage, currentPageIndex, pages, nextPage } =
    usePagination(
      availableSize,
      trafficSituations,
      trafficSituationContainerRefs.current,
      'id',
      {
        elementFlow: layout,
        ignoreItemOrderToPackPages: true,
      }
    );

  const onViewTimeUpdated = useCallback(
    (trafficSituationId: string, newViewTime: number) => {
      setViewTimes((previousViewTimes) => {
        const newViewTimes = new Map(
          [...previousViewTimes.entries()].filter(([trafficSituationId]) =>
            itemsForCurrentPage.some(
              ([trafficSituation]) => trafficSituation.id === trafficSituationId
            )
          )
        );
        newViewTimes.set(trafficSituationId, newViewTime);
        return newViewTimes;
      });
    },
    [itemsForCurrentPage]
  );

  function getMaxItems(): number | undefined {
    if (
      layout === 'vertical' ||
      !trafficSituationsContainerRef.current ||
      availableSize === undefined
    ) {
      return undefined;
    }

    const trafficSituationFontSize = getRemPropertyPixelSize(
      trafficSituationsContainerRef.current,
      '--bread-font-size'
    );
    return Math.max(
      1,
      Math.ceil(
        availableSize /
          (trafficSituationFontSize *
            TRAFFIC_SITUATION_MINIMUM_NUMBER_OF_CHARACTERS)
      )
    );
  }

  const prepareAnimationsAndGoToNextPage = useCallback(() => {
    prepareExitAnimation(currentPageElementRefs);

    nextPage();
  }, [nextPage]);

  return (
    <div
      ref={trafficSituationsContainerRef}
      className={classNames(
        styles['traffic-situations-container'],
        styles[`traffic-situations-container--${layout}`]
      )}
      style={
        {
          '--traffic-situations-max-items': getMaxItems(),
        } as CSSProperties
      }
    >
      <TransitionGroup
        component="div"
        className={classNames(
          styles['traffic-situations'],
          styles[`traffic-situations--${layout}`]
        )}
        style={
          {
            '--available-width': `100%`,
          } as CSSProperties
        }
        aria-hidden="true"
      >
        {itemsForCurrentPage.map(([trafficSituation, paginationId]) => (
          <CSSTransition
            key={paginationId}
            nodeRef={getOrCreateCurrentPageElementRef(paginationId)}
            classNames="paged-item"
            timeout={PAGINATION_TIME_IN_SECONDS * 1000}
            onExited={() => {
              removeCurrentPageElementRef(paginationId);
            }}
          >
            <div
              ref={getOrCreateCurrentPageElementRef(paginationId)}
              className={styles['traffic-situation']}
            >
              <TrafficSituation
                trafficSituation={trafficSituation}
                viewTimeUpdated={onViewTimeUpdated}
              ></TrafficSituation>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
      <div
        ref={pageIndicatorRef}
        className={styles['traffic-situations-container__page-indicator']}
      >
        {pages > 1 && (
          <PageIndicator
            currentPagePaginationTime={maxViewTime}
            currentPageIndex={currentPageIndex}
            pages={pages}
            paging={prepareAnimationsAndGoToNextPage}
          />
        )}
      </div>
      <div
        className={classNames(
          styles['all-traffic-situations'],
          styles[`all-traffic-situations--${layout}`]
        )}
        style={
          {
            '--available-width': `${trafficSituationsAvailableSize.width}px`,
          } as CSSProperties
        }
        aria-live="polite"
      >
        {trafficSituations.map((trafficSituation) => (
          <div
            ref={(trafficSituationContainerElement) => {
              if (trafficSituationContainerElement) {
                trafficSituationContainerRefs.current.set(
                  trafficSituation.id,
                  trafficSituationContainerElement
                );
              } else {
                trafficSituationContainerRefs.current.delete(
                  trafficSituation.id
                );
              }
            }}
            key={trafficSituation.id}
            className={styles['traffic-situation']}
          >
            <TrafficSituation
              trafficSituation={trafficSituation}
            ></TrafficSituation>
          </div>
        ))}
      </div>
    </div>
  );
}
