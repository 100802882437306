import { useRef } from 'react';
import { useScaledFontSize } from '../hooks/useScaledFontSize';
import { Line } from '../models/line';
import styles from './DepartureTableLineCell.module.scss';

interface DepartureTableLineCellProps {
  line: Line;
}

export default function DepartureTableLineCell({
  line,
}: DepartureTableLineCellProps) {
  const lineNameRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(lineNameRef);

  return (
    <td className={styles.line}>
      <div
        ref={lineNameRef}
        className={styles.line__name}
        style={{
          color: line.foregroundColor,
          backgroundColor: line.backgroundColor,
          borderColor: line.foregroundColor,
        }}
      >
        {line.shortName}
      </div>
    </td>
  );
}
