import { useCallback, useReducer, useState } from 'react';
import {
  createBoardConfigurations,
  createConfigurationQuery,
} from '../helpers/boardConfigurationFactory';
import useTheme from '../hooks/useTheme';
import boardConfigurationsReducer, {
  createBoardConfigurators,
} from '../reducers/boardConfiguratorsReducer';
import { BoardConfiguration } from '../models/boardConfiguration';
import BoardConfigurator from './BoardConfigurator';
import { MAX_NUMBER_OF_BOARDS } from '../constants';
import { ConfigurationTheme } from '../models/configurationTheme';
import styles from './Configuration.module.scss';
import classNames from 'classnames';

export default function Configuration() {
  useTheme('light');
  const queryParams = new URLSearchParams(location.search);
  const themeQueryParam = queryParams.get('theme');
  const [theme, setTheme] = useState<ConfigurationTheme>(
    themeQueryParam === 'light' || themeQueryParam === 'sun-based'
      ? themeQueryParam
      : 'dark'
  );
  function onThemeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTheme(event.target.value as ConfigurationTheme);
  }

  const [boardConfigurators, dispatch] = useReducer(
    boardConfigurationsReducer,
    createBoardConfigurators(
      createBoardConfigurations(new URLSearchParams(window.location.search))
    )
  );

  const canAddConfigurators = boardConfigurators.length < MAX_NUMBER_OF_BOARDS;

  function addDepartureBoardConfigurator(): void {
    dispatch({
      type: 'added',
      boardConfiguration: {
        id: -1,
        type: 'stop',
        modules: ['departures', 'trafficSituations'],
      },
    });
  }

  function addConfigurator() {
    dispatch({ type: 'added' });
  }

  const onBoardConfigurationChanged = useCallback(
    (configuratorId: number, boardConfiguration: BoardConfiguration) => {
      dispatch({
        id: configuratorId,
        type: 'addedOrUpdated',
        boardConfiguration,
      });
    },
    []
  );

  const onBoardConfigurationDeleted = useCallback((configuratorId: number) => {
    dispatch({ id: configuratorId, type: 'deleted' });
  }, []);

  const validConfigurators = boardConfigurators.filter(
    (boardConfigurator) => boardConfigurator.isValid
  );
  const boardConfigurations = validConfigurators
    .map((boardConfigurator) => boardConfigurator.boardConfiguration)
    .filter(
      (boardConfiguration): boardConfiguration is BoardConfiguration =>
        boardConfiguration instanceof Object
    );
  const configuration = createConfigurationQuery(boardConfigurations, theme);
  const configurationsHref =
    validConfigurators.length > 0 ? `/?${configuration}` : undefined;

  function copyConfiguration(): void {
    void navigator.clipboard.writeText(configuration);
  }

  function isWindowProxy(windowProxy: unknown): windowProxy is WindowProxy {
    if (!windowProxy || typeof windowProxy !== 'object') {
      return false;
    }

    return 'postMessage' in windowProxy;
  }
  const externalOpener = isWindowProxy(window.opener) ? window.opener : null;

  function useConfiguration(): void {
    if (!externalOpener) {
      return;
    }

    externalOpener.postMessage(configuration, '*');
    window.close();
  }

  return (
    <div className={styles['configuration-container']}>
      <h1 className="sr-only">Konfiguration</h1>
      <div className={styles.configuration}>
        <div className={styles.actions}>
          <button
            type="button"
            disabled={!canAddConfigurators}
            onClick={addDepartureBoardConfigurator}
          >
            Lägg till avgångstavla
          </button>

          <button
            type="button"
            disabled={!canAddConfigurators}
            onClick={addConfigurator}
          >
            Lägg till vy
          </button>
        </div>
        <div className={styles['board-configurators']}>
          {boardConfigurators.map((boardConfigurator) => {
            return (
              <BoardConfigurator
                key={boardConfigurator.id}
                configuratorId={boardConfigurator.id}
                configuration={boardConfigurator.boardConfiguration}
                onChanged={onBoardConfigurationChanged}
                onDeleted={onBoardConfigurationDeleted}
              ></BoardConfigurator>
            );
          })}
        </div>

        <fieldset className={styles.theme}>
          <legend>Tema</legend>
          <label className={styles.option}>
            <input
              type="radio"
              name="theme"
              value="sun-based"
              checked={theme === 'sun-based'}
              onChange={onThemeChange}
            />
            <span>Automatiskt byte vid soluppgång/solnedgång</span>
          </label>
          <br />
          <label className={styles.option}>
            <input
              type="radio"
              name="theme"
              value="dark"
              checked={theme === 'dark'}
              onChange={onThemeChange}
            />
            <span>Mörkt</span>
          </label>
          <br />
          <label className={styles.option}>
            <input
              type="radio"
              name="theme"
              value="light"
              checked={theme === 'light'}
              onChange={onThemeChange}
            />
            <span>Ljust</span>
          </label>
        </fieldset>

        {configurationsHref && !externalOpener && (
          <button
            className={styles['use-configuration']}
            type="button"
            onClick={copyConfiguration}
          >
            Kopiera konfiguration
          </button>
        )}

        {configurationsHref && externalOpener && (
          <button
            className={styles['use-configuration']}
            type="button"
            onClick={useConfiguration}
          >
            Använd configuration
          </button>
        )}

        {configurationsHref && (
          <a
            className={styles.link}
            href={configurationsHref}
            target="_blank"
            rel="noreferrer"
          >
            Länk till skärm
          </a>
        )}

        {configurationsHref && (
          <div className={styles.previews}>
            <div>
              <div
                className={classNames(
                  styles.preview,
                  styles['preview--horizontal']
                )}
              >
                <iframe
                  src={configurationsHref}
                  title="Liggande skala 1:2 för upplösning 1280x720"
                ></iframe>
              </div>
              <label className={styles.description}>
                Förhandsgranskning liggande 1280x720
              </label>
            </div>
            <div>
              <div
                className={classNames(
                  styles.preview,
                  styles['preview--vertical']
                )}
              >
                <iframe
                  src={configurationsHref}
                  title="Stående skala 1:2 för upplösning 720x1280"
                ></iframe>
              </div>
              <label className={styles.description}>
                Förhandsgranskning stående 720x1280
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
