import { useRef } from 'react';
import { Direction } from '../models/direction';
import styles from './DepartureTableDirectionCell.module.scss';
import { useScaledFontSize } from '../hooks/useScaledFontSize';

interface DepartureTableDirectionCellProps {
  direction: Direction;
  tags?: string[];
  headerWidth?: number;
}

export default function DepartureTableDirectionCell({
  direction,
  tags,
  headerWidth,
}: DepartureTableDirectionCellProps) {
  const directionNameRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(directionNameRef);

  const directionViaRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(directionViaRef);
  return (
    <td className={styles.direction} style={{ minWidth: headerWidth }}>
      <div ref={directionNameRef} className={styles.direction__name}>
        {direction.name}
      </div>
      {direction.via && (
        <div ref={directionViaRef} className={styles.direction__via}>
          via {direction.via}
        </div>
      )}
      {tags?.map((tag, tagIndex) => (
        <span key={tag} className={styles.direction__tag}>
          {tag}
          {tagIndex !== tags.length - 1 && <span className="sr-only">, </span>}
        </span>
      ))}
    </td>
  );
}
