import classNames from 'classnames';
import type { TrafficSituation } from '../models/trafficSituation';
import ImageIcon from './ImageIcon';
import TrafficSituationDescription from './TrafficSituationDescription';
import {
  CHARACTERS_READ_PER_SECOND,
  MINIMUM_TIME_IN_SECONDS_TO_READ_TEXT,
} from '../constants';
import styles from './TrafficSituation.module.scss';
import { useCallback, useEffect, useRef } from 'react';
import useElementSize from '../hooks/useElementSize';

interface TrafficSituationProps {
  trafficSituation: TrafficSituation;
  viewTimeUpdated?: (trafficSituationId: string, newViewTime: number) => void;
}

export default function TrafficSituation({
  trafficSituation,
  viewTimeUpdated,
}: TrafficSituationProps) {
  const trafficSituationRef = useRef<HTMLDivElement>(null);
  const trafficSituationSize = useElementSize(trafficSituationRef, {
    boxSizing: 'content-box',
  });
  const headerRef = useRef<HTMLHeadingElement>(null);
  const headerSize = useElementSize(headerRef);
  const availableDescriptionHeight =
    trafficSituationSize.height && headerSize.height
      ? trafficSituationSize.height - headerSize.height
      : undefined;

  const onViewTimeUpdated = useCallback(
    (newViewTime: number) => {
      viewTimeUpdated?.(trafficSituation.id, newViewTime);
    },
    [trafficSituation.id, viewTimeUpdated]
  );

  useEffect(() => {
    if (viewTimeUpdated && !trafficSituation.description) {
      viewTimeUpdated(trafficSituation.id, getTitleViewTime());
    }

    function getTitleViewTime(): number {
      let timeToRead = MINIMUM_TIME_IN_SECONDS_TO_READ_TEXT;

      if (trafficSituation.title) {
        timeToRead +=
          trafficSituation.title.length / CHARACTERS_READ_PER_SECOND;
      }

      return timeToRead;
    }
  }, [
    trafficSituation.description,
    trafficSituation.id,
    trafficSituation.title,
    viewTimeUpdated,
  ]);

  function getIconAltText(): string {
    switch (trafficSituation.severity) {
      case 'normal':
        return 'Störning';

      case 'severe':
        return 'Alvarlig störning';

      default:
        return '';
    }
  }

  return (
    <div
      ref={trafficSituationRef}
      className={classNames(
        styles['traffic-situation'],
        trafficSituation.severity === 'normal' &&
          styles['traffic-situation--normal'],
        trafficSituation.severity === 'severe' &&
          styles['traffic-situation--severe']
      )}
    >
      <header ref={headerRef}>
        <h2 className={styles['traffic-situation__title']}>
          <ImageIcon
            type={`traffic-situation-${trafficSituation.severity}`}
            alt={getIconAltText()}
            className={styles.icon}
          />
          {trafficSituation.title && <span>{trafficSituation.title}</span>}
        </h2>
      </header>
      {trafficSituation.description && (
        <TrafficSituationDescription
          availableHeight={availableDescriptionHeight}
          text={trafficSituation.description}
          timeToReadTitleInSeconds={
            (trafficSituation.title?.length ?? 0) / CHARACTERS_READ_PER_SECOND
          }
          viewTimeUpdated={onViewTimeUpdated}
        />
      )}
    </div>
  );
}
