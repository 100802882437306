import { DepartureRow } from '../models/departureRow';
import classNames from 'classnames';
import DepartureTableLineCell from './DepartureTableLineCell';
import DepartureTableTransportModeCell from './DepartureTableTransportModeCell';
import DepartureTableDirectionCell from './DepartureTableDirectionCell';
import DepartureTableDepartureCell from './DepartureTableDepartureCell';
import DepartureTablePlatformCell from './DepartureTablePlatformCell';
import styles from './DepartureTable.module.scss';
import usePagination from '../hooks/usePagination';
import {
  createRef,
  CSSProperties,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import useElementSize from '../hooks/useElementSize';
import {
  PAGINATION_TIME_IN_SECONDS,
  SECONDS_BETWEEN_DEPARTURES_PAGE_CHANGE,
} from '../constants';
import PageIndicator from './PageIndicator';
import { getRemPropertyPixelSize } from '../helpers/rem';
import { compareDepartureRows } from '../helpers/deparutreRowComparer';
import { DepartureSorting } from '../models/departureSorting';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { prepareExitAnimation } from '../helpers/exitAnimation';

interface DepartureTableProps {
  departureRows: DepartureRow[];
  departureSorting: DepartureSorting;
  showPlatform: boolean;
}

export default function DepartureTable({
  departureRows,
  departureSorting,
  showPlatform,
}: DepartureTableProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerSize = useElementSize(containerRef);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const tableHeadSize = useElementSize(tableHeadRef);
  const pageIndicatorRef = useRef<HTMLDivElement>(null);
  const pageIndicatorSize = useElementSize(pageIndicatorRef);
  const availableHeight =
    containerSize.height && tableHeadSize.height && pageIndicatorSize.height
      ? containerSize.height - tableHeadSize.height - pageIndicatorSize.height
      : undefined;
  const allElementRefs = useRef<Map<string, HTMLTableRowElement>>(new Map());
  const currentPageElementRefs = useRef<
    Map<string, RefObject<HTMLTableRowElement>>
  >(new Map());
  function getOrCreateCurrentRowElementRef(paginationId: string) {
    const existingRef = currentPageElementRefs.current.get(paginationId);
    if (existingRef) {
      return existingRef;
    } else {
      const newRef = createRef<HTMLTableRowElement>();
      currentPageElementRefs.current.set(paginationId, newRef);
      return newRef;
    }
  }
  function removeCurrentRowElementRef(paginationId: string) {
    currentPageElementRefs.current.delete(paginationId);
  }
  const hasThereafterDepartures = departureRows.some(
    (departureRow) => departureRow.thereafter !== undefined
  );
  const renderThereafterColumn = hasThereafterDepartures;
  const hasDeparturesWithPlatform = departureRows.some(
    (departureRow) => departureRow.platform !== undefined
  );
  const renderPlatformColumn = showPlatform && hasDeparturesWithPlatform;

  const directionHeaderRef = useRef<HTMLTableCellElement>(null);
  const directionHeaderSize = useElementSize(directionHeaderRef);

  const departureHeaderRef = useRef<HTMLTableCellElement>(null);
  const departureHeaderSize = useElementSize(departureHeaderRef);

  const thereafterDepartureHeaderRef = useRef<HTMLTableCellElement>(null);
  const thereafterDepartureHeaderSize = useElementSize(
    thereafterDepartureHeaderRef,
    { renderRef: renderThereafterColumn }
  );

  const platformHeaderRef = useRef<HTMLTableCellElement>(null);
  const platformHeaderSize = useElementSize(platformHeaderRef, {
    renderRef: renderPlatformColumn,
  });

  const sortedDepartureRows = useMemo(
    () =>
      [...departureRows].sort((first, second) =>
        compareDepartureRows(first, second, departureSorting)
      ),
    [departureRows, departureSorting]
  );

  const pageChangeSortingFunction = useCallback(() => {
    if (departureSorting === 'time') {
      return (first: DepartureRow, second: DepartureRow) =>
        compareDepartureRows(first, second, departureSorting);
    }

    return undefined;
  }, [departureSorting]);

  const { items, itemsForCurrentPage, currentPageIndex, pages, nextPage } =
    usePagination(
      availableHeight,
      sortedDepartureRows,
      allElementRefs.current,
      'id',
      {
        directlyUpdateItemsWithMostCurrentItems: true,
        pageChangeSortingFunction: pageChangeSortingFunction(),
      }
    );

  const hasNextDepartureWithTrafficSituation = items.some(
    (departureRow) => departureRow.next.trafficSituationSeverity !== undefined
  );
  const hasThereafterDepartureWithTrafficSituation = items.some(
    (departureRow) =>
      departureRow.thereafter?.trafficSituationSeverity !== undefined
  );
  function getExpandDirectionWidth(): boolean {
    if (!containerRef.current || containerSize.width === undefined) {
      return false;
    }
    const directionTextWidth =
      getRemPropertyPixelSize(containerRef.current, '--heading-2-font-size') *
      12;
    const directionWidthRatio = directionTextWidth / containerSize.width;
    return directionWidthRatio > 0.5;
  }
  const expandDirectionWidth = getExpandDirectionWidth();

  const prepareAnimationsAndGoToNextPage = useCallback(() => {
    prepareExitAnimation(currentPageElementRefs);

    nextPage();
  }, [nextPage]);

  const dividerWidth =
    tableHeadSize.width !== undefined ? `${tableHeadSize.width}px` : undefined;

  return (
    <div
      ref={containerRef}
      className={styles['departure-table']}
      style={{ '--divider-width': dividerWidth } as CSSProperties}
    >
      <table className={styles['all-departures']}>
        <thead ref={tableHeadRef}>
          <tr>
            <th scope="col">
              <span className="sr-only">Linje</span>
            </th>
            <th scope="col">
              <span className="sr-only">Fordonstyp</span>
            </th>
            <th
              ref={directionHeaderRef}
              scope="col"
              className={styles.direction}
              style={{ width: expandDirectionWidth ? '100%' : undefined }}
            >
              <span className="sr-only">Mot</span>
            </th>
            <th
              ref={departureHeaderRef}
              scope="col"
              className={styles.departure}
            >
              Nästa <span aria-hidden="true">(min)</span>
            </th>
            {renderThereafterColumn && (
              <th
                ref={thereafterDepartureHeaderRef}
                scope="col"
                className={styles['thereafter-departure']}
              >
                Därefter
              </th>
            )}
            {renderPlatformColumn && (
              <th
                ref={platformHeaderRef}
                scope="col"
                className={styles.platform}
              >
                Läge
              </th>
            )}
          </tr>
        </thead>
        <tbody aria-live="polite">
          {departureRows.map((departureRow) => (
            <tr
              key={departureRow.id}
              ref={(rowElement) => {
                if (rowElement) {
                  allElementRefs.current.set(departureRow.id, rowElement);
                } else {
                  allElementRefs.current.delete(departureRow.id);
                }
              }}
            >
              <DepartureTableLineCell line={departureRow.line} />
              <DepartureTableTransportModeCell
                transportMode={departureRow.transportMode}
              />
              <DepartureTableDirectionCell
                direction={departureRow.direction}
                tags={departureRow.tags}
              />
              <DepartureTableDepartureCell
                departure={departureRow.next}
                compact={expandDirectionWidth}
                hasDepartureWithTrafficSituation={
                  hasNextDepartureWithTrafficSituation
                }
              />
              {renderThereafterColumn && (
                <DepartureTableDepartureCell
                  departure={departureRow.thereafter}
                  compact={expandDirectionWidth}
                  hasDepartureWithTrafficSituation={
                    hasThereafterDepartureWithTrafficSituation
                  }
                />
              )}
              {renderPlatformColumn && (
                <DepartureTablePlatformCell platform={departureRow.platform} />
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <table
        className={styles.departures}
        style={{ marginTop: `${tableHeadSize.height}px` }}
        aria-hidden="true"
      >
        <TransitionGroup component="tbody">
          {itemsForCurrentPage.map(([departureRow, paginationId], index) => (
            <CSSTransition
              key={paginationId}
              nodeRef={getOrCreateCurrentRowElementRef(paginationId)}
              classNames="paged-item"
              timeout={PAGINATION_TIME_IN_SECONDS * 1000}
              onExited={() => {
                removeCurrentRowElementRef(paginationId);
              }}
            >
              <tr
                ref={getOrCreateCurrentRowElementRef(paginationId)}
                className={classNames(
                  styles['departure-row'],
                  index + 1 === itemsForCurrentPage.length &&
                    styles['departure-row--last-for-page']
                )}
              >
                <DepartureTableLineCell line={departureRow.line} />
                <DepartureTableTransportModeCell
                  transportMode={departureRow.transportMode}
                />
                <DepartureTableDirectionCell
                  direction={departureRow.direction}
                  tags={departureRow.tags}
                  headerWidth={directionHeaderSize.width}
                />
                <DepartureTableDepartureCell
                  departure={departureRow.next}
                  compact={expandDirectionWidth}
                  hasDepartureWithTrafficSituation={
                    hasNextDepartureWithTrafficSituation
                  }
                  headerWidth={departureHeaderSize.width}
                />
                {renderThereafterColumn && (
                  <DepartureTableDepartureCell
                    departure={departureRow.thereafter}
                    compact={expandDirectionWidth}
                    hasDepartureWithTrafficSituation={
                      hasThereafterDepartureWithTrafficSituation
                    }
                    headerWidth={thereafterDepartureHeaderSize.width}
                  />
                )}
                {renderPlatformColumn && (
                  <DepartureTablePlatformCell
                    platform={departureRow.platform}
                    headerWidth={platformHeaderSize.width}
                  />
                )}
              </tr>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </table>
      <div
        ref={pageIndicatorRef}
        className={styles['departure-table__page-indicator']}
      >
        {pages > 1 && (
          <PageIndicator
            currentPagePaginationTime={SECONDS_BETWEEN_DEPARTURES_PAGE_CHANGE}
            currentPageIndex={currentPageIndex}
            pages={pages}
            paging={prepareAnimationsAndGoToNextPage}
          />
        )}
      </div>
    </div>
  );
}
