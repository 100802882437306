import { getEnvironment } from '../helpers/environment';
import { VERSION } from '../version';
import styles from './EnvironmentLabel.module.scss';

export default function EnvironmentLabel() {
  const environment = getEnvironment();
  if (environment !== 'Test' && environment !== 'UAT') {
    return undefined;
  }
  return (
    <div className={styles['environment-label']}>
      <span className="sr-only">Körs i </span>
      <span className={styles.environment}>{environment}</span>
      <span className="sr-only"> med version </span>
      <span className={styles.version}>{VERSION}</span>
    </div>
  );
}
