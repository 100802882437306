import useTheme from '../hooks/useTheme';
import styles from './Help.module.scss';

export default function Help() {
  useTheme('light');

  const boardNumber = '{n}';

  return (
    <div className={styles.help}>
      <h1>Pages and query-parameters</h1>
      <h2>(default page) - Generic digital sign</h2>
      <p>
        Shows one to many generic digital sign boards from a GDS configuration
        which is provided as query parameters. Most query parameters are tied to
        a specific board with a number ranging from 1-10, which will be written
        as “board{boardNumber}” for the parameters. The first board must start
        with number 1 and the following board numbers must be in sequence, each
        board will provide a separate view in the web page.
      </p>
      <p>
        <a
          href="/?board1Gids=9021014001760000&board1Modules=departures&board1Modules=trafficSituations"
          target="_blank"
        >
          Example link configuration of departure board for Brunnsparken
        </a>
      </p>

      <h2>
        <a href="/configuration" target="_blank">
          /configuration
        </a>{' '}
        - Configuration builder
      </h2>
      <p>Helps you build GDS configuration using a graphical interface.</p>

      <h3>board{boardNumber}Modules</h3>
      <p>
        Sets which modules to be used for the board. Valid values are
        “departures” and “trafficSituations”, this parameter can be set multiple
        times to set multiple modules.
      </p>

      <h3>board{boardNumber}Gids</h3>
      <p>
        Sets which gids to be used as data source. This can either be one stop
        area gid or multiple stop point gids by setting the parameter multiple
        times. If multiple stop point gids are provided they must belong to the
        same stop area.
      </p>

      <h3>board{boardNumber}DepartureSorting</h3>
      <p>
        Sets how departures should be sorted. Valid values are “alphabetical” or
        “time”. “alphabetical” sorts on line name, direction, and via, while
        “time” sorts on departure time primary and alphabetically secondary.
        Default value is “alphabetical”.
      </p>

      <h3>board{boardNumber}ShowPlatformsInHeader</h3>
      <p>
        Sets if platforms should be shown in header. Default value is “true”.
      </p>

      <h3>theme</h3>
      <p>
        Sets the theme to be used. Valid values are “sun-based”, “light”, or
        “dark”. “sun-based” changes theme automatically to light theme when the
        sun is up and to dark theme when the sun is down. Default value is
        “dark”.
      </p>
    </div>
  );
}
