import { StopBoardConfiguration } from '../models/boardConfiguration';
import { DepartureRow } from '../models/departureRow';
import DepartureTable from './DepartureTable';
import DeparturesMessage from './DeparturesMessage';
import LoadingPanel from './LoadingPanel';

interface DeparturesModuleProps {
  boardConfiguration: StopBoardConfiguration;
  departureRows?: DepartureRow[];
  departureRowsError?: boolean;
}

export default function DeparturesModule({
  boardConfiguration,
  departureRows,
  departureRowsError,
}: DeparturesModuleProps) {
  if (departureRowsError) {
    return (
      <DeparturesMessage
        title="Just nu har vi tekniska problem med denna avgångstavla"
        text="Sök din resa i appen Västtrafik To Go eller på vasttrafik.se"
      />
    );
  } else if (departureRows === undefined) {
    return <LoadingPanel />;
  } else if (departureRows.length > 0) {
    return (
      <DepartureTable
        departureRows={departureRows}
        departureSorting={boardConfiguration.departureSorting ?? 'alphabetical'}
        showPlatform={
          boardConfiguration.type === 'stop-area' ||
          boardConfiguration.stopPointGids.length > 1
        }
      />
    );
  } else {
    return (
      <DeparturesMessage
        title="Det går inga avgångar härifrån den närmsta timmen"
        text="Det är minst en timme kvar till nästa tur avgår. Sök alternativa resor i appen Västtrafik To Go eller på vasttrafik.se"
      />
    );
  }
}
