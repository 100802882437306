import classNames from 'classnames';
import { Departure } from '../models/departure';
import ImageIcon, { Icon } from './ImageIcon';
import { useDepartureDisplayTime } from '../hooks/useDepartureDisplayTime';
import styles from './DepartureTableDepartureCell.module.scss';

interface DepartureTableDepartureCellProps {
  departure: Departure | undefined;
  compact: boolean;
  hasDepartureWithTrafficSituation: boolean;
  headerWidth?: number;
}

export default function DepartureTableDepartureCell({
  departure,
  compact,
  hasDepartureWithTrafficSituation,
  headerWidth,
}: DepartureTableDepartureCellProps) {
  const departureDisplayTime = useDepartureDisplayTime(departure);

  const trafficSituationIconType: Icon | undefined =
    departure?.trafficSituationSeverity
      ? `traffic-situation-${departure.trafficSituationSeverity}`
      : undefined;

  return (
    <td
      className={classNames(
        styles.departure,
        departure?.isCancelled && styles['departure--cancelled']
      )}
      style={{ minWidth: headerWidth }}
    >
      <div
        className={classNames(
          styles['departure-information'],
          !departure?.isCancelled &&
            departure?.isWheelchairAccessible &&
            styles['departure-information--wheelchair-accessible'],
          hasDepartureWithTrafficSituation &&
            styles[
              'departure-information--departure-with-traffic-situation-exist'
            ],
          compact && styles['departure-information--compact']
        )}
      >
        {trafficSituationIconType && (
          <ImageIcon
            type={trafficSituationIconType}
            alt="Avgångstiden har ett störningsmeddelande"
            className={styles['connected-traffic-situation']}
          ></ImageIcon>
        )}
        {departureDisplayTime && (
          <span className={styles['departure-information__text']}>
            {departureDisplayTime}
          </span>
        )}
        {departureDisplayTime && typeof departureDisplayTime === 'number' && (
          <span className="sr-only">minuter</span>
        )}
        {departure &&
          !departure.isCancelled &&
          departure.isWheelchairAccessible && (
            <ImageIcon
              type="wheelchair-accessible"
              alt="Resan är anpassad för rullstol"
              className={styles['wheelchair-accessible']}
            ></ImageIcon>
          )}
      </div>
    </td>
  );
}
