import { MAX_NUMBER_OF_BOARDS } from '../constants';
import { BoardConfiguration } from '../models/boardConfiguration';
import { ConfigurationTheme } from '../models/configurationTheme';
import { DepartureSorting } from '../models/departureSorting';
import { Module } from '../models/module';
import { isStopArea, isStopPoint } from './gid';

export function createBoardConfigurations(
  urlParams: URLSearchParams
): BoardConfiguration[] {
  function getModules(boardId: number): Module[] {
    const moduleCandidates = urlParams.getAll(`board${boardId}Modules`);
    const modules = new Array<Module>();
    for (const moduleCandidate of moduleCandidates) {
      if (
        moduleCandidate === 'departures' ||
        moduleCandidate === 'trafficSituations'
      ) {
        modules.push(moduleCandidate);
      } else {
        throw new Error(
          `Invalid module ${moduleCandidate} for board ${boardId}`
        );
      }
    }
    return modules;
  }

  function getShowPlatformsInHeader(boardCounter: number): boolean | undefined {
    const showPlatformsInHeader = urlParams.get(
      `board${boardCounter}ShowPlatformsInHeader`
    );
    return showPlatformsInHeader === 'false' ? false : undefined;
  }

  function getDepartureSorting(
    boardCounter: number
  ): DepartureSorting | undefined {
    const departureSorting = urlParams.get(
      `board${boardCounter}DepartureSorting`
    );
    return departureSorting === 'time' ? departureSorting : undefined;
  }

  let boardId = 1;
  let hasBoardAtId = true;
  const newBoards = new Array<BoardConfiguration>();
  while (hasBoardAtId) {
    hasBoardAtId = false;

    const gids = urlParams.getAll(`board${boardId}Gids`);
    const stopAreaGid =
      gids.length === 1 && isStopArea(gids[0]) ? gids[0] : undefined;

    if (stopAreaGid) {
      newBoards.push({
        id: boardId,
        type: 'stop-area',
        stopAreaGid,
        modules: getModules(boardId),
        departureSorting: getDepartureSorting(boardId),
      });
      hasBoardAtId = true;
      boardId++;
    } else if (gids.length > 0 && gids.every(isStopPoint)) {
      newBoards.push({
        id: boardId,
        type: 'stop-points',
        stopPointGids: gids,
        modules: getModules(boardId),
        showPlatformsInHeader: getShowPlatformsInHeader(boardId),
        departureSorting: getDepartureSorting(boardId),
      });
      hasBoardAtId = true;
      boardId++;
    }
  }

  // Legacy configuration for Avgångstavla
  const stopAreaGids = urlParams.getAll('stopAreaGid');
  for (const stopAreaGid of stopAreaGids) {
    newBoards.push({
      id: boardId++,
      type: 'stop-area',
      stopAreaGid,
      modules: ['departures', 'trafficSituations'],
    });
  }

  if (newBoards.length > MAX_NUMBER_OF_BOARDS) {
    throw new Error(`Max number of boards is ${MAX_NUMBER_OF_BOARDS}`);
  }

  return newBoards;
}

export function createConfigurationQuery(
  boardConfigurations: BoardConfiguration[],
  theme: ConfigurationTheme
): string {
  const urlParams = new URLSearchParams();
  for (const boardConfiguration of boardConfigurations) {
    if (boardConfiguration.type === 'stop-area') {
      addGid(urlParams, boardConfiguration.id, boardConfiguration.stopAreaGid);
    } else {
      for (const stopPointGid of boardConfiguration.stopPointGids) {
        addGid(urlParams, boardConfiguration.id, stopPointGid);
      }

      if (!boardConfiguration.showPlatformsInHeader) {
        urlParams.set(
          `board${boardConfiguration.id}ShowPlatformsInHeader`,
          'false'
        );
      }
    }

    for (const module of boardConfiguration.modules) {
      urlParams.append(`board${boardConfiguration.id}Modules`, module);
    }

    if (
      boardConfiguration.departureSorting &&
      boardConfiguration.departureSorting !== 'alphabetical'
    ) {
      urlParams.set(
        `board${boardConfiguration.id}DepartureSorting`,
        boardConfiguration.departureSorting
      );
    }
  }

  if (theme !== 'dark') {
    urlParams.set(`theme`, theme);
  }

  return urlParams.toString();
}

function addGid(urlParams: URLSearchParams, boardId: number, gid: string) {
  urlParams.append(`board${boardId}Gids`, gid);
}
