import { useRef } from 'react';
import styles from './DepartureTablePlatformCell.module.scss';
import { useScaledFontSize } from '../hooks/useScaledFontSize';

interface DepartureTablePlatformCellProps {
  platform?: string;
  headerWidth?: number;
}

export default function DepartureTablePlatformCell({
  platform,
  headerWidth,
}: DepartureTablePlatformCellProps) {
  const textRef = useRef<HTMLSpanElement>(null);
  useScaledFontSize(textRef, {
    enabled: platform !== undefined && platform.length > 1,
  });
  return (
    <td className={styles.platform} style={{ minWidth: headerWidth }}>
      {platform && (
        <span className={styles.container}>
          <span ref={textRef} className={styles.container__text}>
            {platform}
          </span>
        </span>
      )}
    </td>
  );
}
