import { useEffect, useState } from 'react';
import { measureTextWidth } from '../helpers/measureTextWidth';

/**
 * Returns the text width for a specific font.
 *
 * @param text The text to measure.
 * @param font The name of the font, defined with the "define-font" mixin, to use when measuring.
 * @returns The width of the text rendered with the font.
 */
export default function useTextWidth(text: string, fontName: string): number {
  const [width, setWidth] = useState(measureTextWidth(text, fontName));

  useEffect(() => {
    setWidth(measureTextWidth(text, fontName));

    function onStyleChange(): void {
      setWidth(measureTextWidth(text, fontName));
    }
    const rootStyleObserver = new MutationObserver(onStyleChange);
    rootStyleObserver.observe(document.documentElement, {
      attributeFilter: ['style'],
    });

    return () => {
      rootStyleObserver.disconnect();
    };
  }, [fontName, text]);

  return width;
}
